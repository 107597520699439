<template>
  <div style="height:100%">
    <vl-map :load-tiles-while-animating="false" :load-tiles-while-interacting="false"
             data-projection="EPSG:4326" style="height: 100%">
      <vl-view :zoom.sync="zoom" :center.sync="center" :rotation.sync="rotation"></vl-view>

      
      <vl-layer-tile id="osm">
        <vl-source-osm></vl-source-osm>
      </vl-layer-tile>
      
       <vl-feature v-if="tracker_position" id="point" :properties="{prop: 'value', prop2: 'value'}">
          <vl-geom-point :coordinates="tracker_position"></vl-geom-point>
      </vl-feature>
      
    <vl-overlay id="overlay" :position="tracker_position">
      <template>
        <div v-if="tracker" class="overlay-content">
          <h3>{{tracker.city}}</h3>
          {{tracker.date}}<br>
          {{tracker.temperature}}°C<br/>
          <a :href="get_maps_url()" target="blank">View on Maps</a>
        </div>
      </template>
    </vl-overlay>
      
    </vl-map>
  </div>
</template>

<script>

import Vue from 'vue'

  export default {
    props: ['user'],
    data () {
      return { 
        zoom: 10,
        center: [0, 0],
        trips:[],
        tracker_position: undefined,
        tracker: undefined,
        rotation: 0,
      }
    },
    methods: {
    reload() {
        let self = this
       fetch('/api/position').then((response) => {
        return response.json().then((json) => {
            setTimeout(this.reload, 60*1000)
            this.tracker_position = [json["longitude"], json["latitude"]]
            this.center = this.tracker_position
            Vue.set(this, 'tracker', json)

        })
        }).catch(function() {
            setTimeout(self.reload, 10000)
        });
    },
    trips_count() {
        return this.trips.length
    },
    fetch_trips() {
       let self = this
       fetch('/api/trips').then((response) => {
        return response.json().then((json) => {
            Vue.set(this, 'trips', json)

        })
        }).catch(function() {
            setTimeout(self.reload, 10000)
        });
    },
    is_logged() {
        if(this.user === null ||  this.user === undefined) {
            return false;
        }
        return this.user.length > 0
    },
    is_live(trip) {
        return trip.datestop == 'None'
    },
    get_maps_url(){
      return 'https://www.google.com/maps?q=loc:'+this.tracker_position[1]+','+this.tracker_position[0]
    },
  },
    mounted: function() {
        setTimeout(this.reload, 1000)
        this.fetch_trips()
  }
  }
</script>

<style>

  .live {
      color:red;
  }
.overlay-content a, .overlay-content a:visited {
 color: white;
}

.overlay-content {
    background-color:rgba(0,0,100,0.5);
    color: white;
}

</style>
