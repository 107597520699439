<template>
  <div style="height:100%">
       <form method="POST" action="/api/trips/add" enctype="multipart/form-data">
            <ul>
                <li>Title: <input name="title"/></li>
                <li><select name="mode">
                    <option id="foot">foot</option>
                    <option id="bike">bike</option>
                    <option id="boat">boat</option>
                </select></li>
                <li>Date format: dd/mm/yyyy hh:mm</li>
                <li>Datestart: <input name="datestart" /></li>
                <li>Datestop: <input name="datestop" /></li>
            </ul>
            <button type="submit" >Submit</button>
        </form>
  </div>
</template>

<script>

export default {
    props: ['user'],
    data () {
      return { 
        zoom: 10,
        center: [0, 0],
        trips:[],
        tracker_position: undefined,
        tracker: undefined,
        rotation: 0,
      }
    },
    methods: {
        is_logged() {
            if(this.user === null ||  this.user === undefined) {
                return false;
            }
            return this.user.length > 0
        },
    },
    mounted: function() {
  }
  }
</script>

<style scoped>

input{
    border: 1px solid black;
}


</style>
