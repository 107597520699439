<template>
   <div style="height:100%">
     <div id="overlay" style="height:100%" v-if="overlay_display()">
        Loading...
    </div>
  <div class="wrapper">
    <v-dialog persistent
      v-model="dialog"
      width="95%"
      >
      <v-card class="dialog">
        <v-card-title>
            details
        </v-card-title>
        <div v-if="displayed_record != null">
            <b>{{displayed_record.city}}</b><br/>
            {{displayed_record.date}}<br/>
            {{displayed_record.temperature}}°C<br/>
            <a :href="get_maps_url(displayed_record.longitude,displayed_record.latitude)">View on Maps</a>
        </div>
        
        <ul class="medias-list">
            <li v-for="m in get_displayed_medias()" :key="get_displayed_media_name(m)" class="image-preview">
                <div v-if="is_logged" >
                    <v-btn @click="delete_media(m)">Delete</v-btn>
                    <v-btn @click="set_position_media(m)">Position</v-btn> <br/>
                    <v-btn @click="rotate_media(m, 90)">Rotate 90</v-btn>
                    <v-btn @click="rotate_media(m, 180)">Rotate 180</v-btn>
                    <v-btn @click="rotate_media(m, 270)">Rotate 270</v-btn>
                </div>
                <span>{{m.title}}</span> {{m.city}}
                <img :src="get_media_path(m)" />
                <div>{{m.description}}</div>
            </li>
        </ul>

        <v-card-actions>
          <div class="actions">
            <v-btn
              class="action-btn"
              color="#888"
              @click="dialog=false"
              >Fermer</v-btn>
          </div>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>



    <vl-map ref="map" :load-tiles-while-animating="false" :load-tiles-while-interacting="false"
             data-projection="EPSG:4326" style="height: 100%" @pointermove="mousemove" @click="click">
      <vl-view :zoom.sync="zoom" :center.sync="center" :rotation.sync="rotation"></vl-view>

      
      <vl-layer-tile id="osm">
        <vl-source-osm></vl-source-osm>
      </vl-layer-tile>
      
      <vl-feature v-for="p in get_record_raw()" :key="get_raw_name(p)" :properties="p" :id="get_raw_name(p)">
        <vl-geom-point :coordinates="[p.longitude,p.latitude]"/>
        <vl-style>
            <vl-style-circle radius="5">
                <vl-style-fill color="rgba(255,0,0,0.8)"></vl-style-fill>
                <vl-style-stroke color="rgb(255,0,0)" :width="1"></vl-style-stroke>
            </vl-style-circle>
        </vl-style>
      </vl-feature>
      
      
      <vl-feature v-if="record_last && is_live()" :properties="record_last">
        <vl-geom-point :coordinates="[record_last.longitude,record_last.latitude]"/>
        <vl-style>
            <vl-style-circle :radius="record_last_radius()">
                <vl-style-stroke color="rgb(255,0,0)" :width="3"></vl-style-stroke>
            </vl-style-circle>
        </vl-style>
      </vl-feature>  

      
        <vl-feature v-for="m in get_medias()" :key="get_media_name(m)" :properties="m" :id="get_media_name(m)">
        <vl-geom-point :coordinates="[m.longitude,m.latitude]"/>
            <vl-style>
                
            <vl-style-icon
            src="/markers/camera.png"
            :scale="1"
            :anchor="[0.5, 1]"
            ></vl-style-icon>
                
            </vl-style>
      </vl-feature>  
      
        <vl-feature v-if="get_records().length > 0" id="line-string">
          <vl-geom-line-string :coordinates="get_records()"></vl-geom-line-string>
            <vl-style-box>
                <vl-style-stroke color="red" :width="2"></vl-style-stroke>
            </vl-style-box>
      </vl-feature>
      
    </vl-map>
    
    <div id="map-overlay-no-data" v-if="records_raw.length == 0 && is_live()">
        <h2>{{trip.title}} <span v-if="is_live()" style="color:red">(Live)</span></h2>
        <br/>
        Waiting for live data...
        <div v-if="is_logged" >
            <v-btn v-if="is_live()" @click="end_trip()">Finish</v-btn>
        </div>
    </div>
    
    <div id="map-title">
      <h2>{{mode_logo()}} {{trip.title}} <span v-if="is_live()" style="color:red">(Live)</span></h2> <b>{{distance()}}km</b> {{duration()}}h
    </div>

    <div id="map-tools" v-if="is_logged">
        {{mode}} <v-btn @click="toggle_mode()">Toggle</v-btn>
                <v-btn v-if="is_live()" @click="end_trip()">Finish</v-btn>
                <v-btn @click="remove_trip()">remove trip</v-btn>
                <br/>
                <a style="color:red;" :href="get_basic_upload_url()" >Basic upload</a> 
                <form v-if="!is_uploading">
                  <label>Title</label><input ref="title" type="text"><br/>
                  <label>Description</label><textarea ref="description"></textarea><br/>
                    <div>
                        <label>Select file to upload</label>
                        <input ref="upload" type="file">
                    </div>
                    <v-btn @click="upload()">Upload</v-btn>
                </form>

    </div>

  </div>
</template>

<script>

import Vue from 'vue'

  export default {
    props: ['id', 'is_logged'],
    data () {
      return { 
        zoom: 10,
        center: [0, 0],
        trip:{
            title: ""
        },
        records: [],
        records_raw: [],
        rotation: 0,
        displayed_medias : [],
        mode:'view',
        update: true,
        mediaposition : undefined,
        displayed_record : null,
        is_uploading:false,
        media_big: true,
        record_last: null,
        last_radius:25,
        last_radius_step:1,
        dialog: false
      }
    },
    watch: {
      id() {
        if(this.id) {
          this.fetch_trip()
        }
      }
    },


    methods: {
    get_basic_upload_url() {
      return '/api/medias/upload-basic/'+this.id
    },
    get_raw_name(feature) {
        return "RAW_" + feature.id
    },
    get_record_raw() {
        if (this.trip.records_raw !== undefined) {
            return this.trip.records_raw
        }
        return []
    },
    get_medias() {
        if (this.trip.medias !== undefined) {
            return this.trip.medias
        }
        return []
    },
    get_media_name(feature) {
        return "MEDIA_" + feature.id
    },
    get_displayed_medias() {
        return this.displayed_medias
    },
    get_displayed_media_name(m) {
        return 'DISPLAYEDMEDIA_'+m.id
    },
    get_media_path(m) {
        if(this.media_big) {
            return '/api/medias/view/'+m.id
        } else {
            return '/api/medias/preview/'+m.id
        }
    },
    media_big_toggle() {
        this.media_big = ! this.media_big
    },
    fetch_trip() { 
      if(!this.is_live()) {
        this.records = []
        this.records_raw = []
      }

       fetch('/api/trips/view/'+this.id).then((response) => {
        return response.json().then((json) => {
          document.title = 'trip '+json.title
          if(this.is_live()) {
             this.records = []
             this.records_raw = []
             document.title = 'trip '+json.title+ ' LIVE'
           }
            Vue.set(this, 'trip', json)
                for(let i in json.records) {
                this.records.push([json.records[i][0], json.records[i][1] ])
            }
            for(let i in json.records_raw) {
                this.records_raw.push([json.records_raw[i].longitude, json.records_raw[i].latitude ])
            }
            if(this.center[0] == 0 && this.center[1] == 0) {
                this.center = this.records_raw[0]
            }
            if(json.records_raw.length > 1) {
                this.record_last = json.records_raw[0]
            }
            
            if(this.is_live()) {
                setTimeout(this.fetch_trip, 10000)
            }
        })
        }).catch(function() {
        });
    },
    remove_trip() {
        fetch('/api/trips/delete/'+this.id).then((response) => {
        return response.json().then((json) => {
            console.log(json)
            this.fetch_trip()
        })
        }).catch(function() {
        });
    },
    is_live() {
        return this.trip.datestop == 'None'
    },
    hover_feature(f) {
        let id = f.id_
        if(id.includes('MEDIA')) {
            this.display_media(f.getProperties())
        } else if(id.includes('RAW')) {
            this.display_record(f.getProperties())
        }
    },
    click_feature(f) {
        let id = f.id_
        if(this.mode == 'edit') {
            if(id.includes('RAW')) {
                this.remove_raw(f.getProperties().id)
            }
          return
        }
        this.hover_feature(f)
        this.dialog = true
    },
    remove_raw(id) {
        console.log("removing "+id)
        this.trip.records_raw = this.trip.records_raw.filter(item => item.id !== id)
        fetch('/api/records/delete/'+id).then((response) => {
        return response.json().then((json) => {
            console.log(json)
            this.fetch_trip()
        })
        }).catch(function() {
        });
        
    },
    display_media(m) {
        this.displayed_medias.push(m)
    },
    display_record(r) {
        console.log(r)
        this.displayed_record = r
    },
    click(e) {
        if(this.mediaposition != undefined) {
            this.set_position_media_coord(this.mediaposition, e.coordinate[1], e.coordinate[0])
            this.mediaposition = undefined
            return
        }
        this.update = true;
        this.displayed_medias = []
        let self = this
        function cb(f) {
            self.update = false;
            self.click_feature(f)
        }
        this.$refs.map.forEachFeatureAtPixel(e.pixel, cb)
    },
    mousemove() {
        if(! this.update) {
            return
        }
    },
    distance() {
        if(this.trip.distance === undefined) {
          return '??'
        } else {
          return Math.floor(this.trip.distance/1000.)
        }
    },
    duration() {
        return Math.floor(this.trip.duration/(60*60))
    },
    toggle_mode() {
        if(this.mode == 'view') {
            this.mode = 'edit'
        } else {
            this.mode = 'view'
        }
    },
    upload() {
        this.is_uploading = true
        let file = this.$refs.upload.files[0]
        
        let formData = new FormData();
        formData.append('file', file);
        formData.append('title', this.$refs.title.value);
        formData.append('description', this.$refs.description.value);
        formData.append('tripid', this.id);
        
        let self=this
        fetch('/api/medias/upload', { // Your POST endpoint
            method: 'POST',
            headers: {
            },
            body: formData // This is your file object
        }).then(
            response => { 
            self.is_uploading = false
            response.json()
            } // if the response is a JSON object
        ).then(
            success => console.log(success) // Handle the success response object
        ).catch(
            error => {
                alert(error) // Handle the error response object
            }
        );
    },
    delete_media(m) {
        let id = m.id
        this.trip.medias = this.trip.medias.filter(item => item.id !== id)
        fetch('/api/medias/delete/'+id).then((response) => {
        return response.json().then((json) => {
            console.log(json)
            this.fetch_trip()
        })
        }).catch(function() {
        });
    },
    set_position_media(m) {
        this.mediaposition = m
    },
    rotate_media(m, angle) {
        console.log(m,angle)
        fetch('/api/medias/rotate/'+m.id+':'+angle).then((response) => {
        return response.json().then((json) => {
            console.log(json)
            this.fetch_trip()
        })
        }).catch(function() {
        });
    },
    set_position_media_coord(m, lat, lon){
        fetch('/api/medias/update/'+m.id+'?latitude='+lat+'&longitude='+lon).then((response) => {
        return response.json().then((json) => {
            console.log(json)
            this.fetch_trip()
        })
        }).catch(function() {
        });
    },
    end_trip() {
        fetch('/api/trips/finish/'+this.id).then((response) => {
        return response.json().then((json) => {
            console.log(json)
            this.fetch_trip()
        })
        }).catch(function() {
        });
    },
    record_last_radius() {
        return this.last_radius
    },
    get_records() {
        if(this.records.length == 0) {
          return this.records_raw
        }
        return this.records
    },
    mode_logo() {
      if(this.trip.mode == "TripMode.bike") {
        return "🚴"
      } else if(this.trip.mode == "TripMode.boat") {
        return "🚣"
      } else if (this.trip.mode == "TripMode.car") {
        return "🚘"
      } else {
        return "👟"
      }
    },
    overlay_display(){
      return this.records_raw.length == 0
    },
   get_maps_url(lat,lon){
      return 'https://www.google.com/maps?q=loc:'+lon+','+lat
    },

  },
    mounted: function() {
        this.fetch_trip()
  }
  }
</script>

<style>

#map-title {
    position: absolute;
    top:0px;
    height:100px;
    right:0px;
    background-color:rgba(0,0,0, 0.5);
    color: white;
    padding: 20px;
}

#map-tools {
    position: absolute;
    bottom:0px;
    right:0px;
    background-color:rgba(0,0,0, 0.5);
    color: white;
    padding: 20px;
}


#map-overlay-no-data{
    position: absolute;
    top:35%;
    left:35%;
    width:20%;
    height:20%;
    background-color:rgba(0,0,0, 0.5);
    color: white;
    padding: 20px;
}

input, textarea{
    border: 1px solid black;
}
.overlay-content {
    background-color:rgba(0,0,100,0.5);
    color: white;
}

.image-preview img {
    max-width:100%;
}

#overlay {
  background-color:rgba(0,0,0,0.3);
}

.dialog {
  padding:3em;
}

.medias-list {
  list-style: none;
}

</style>
