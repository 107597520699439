<template>
  <v-app>
    <v-navigation-drawer app clipped
      v-model="drawer"
      >

        <v-list-item link
            v-for="trip in trips"
            :key="trip.id"
            :to="{ path: '/trips/view/'+trip.id, params: { id: trip.id }}"
            >
            <v-list-item-icon>
              <v-icon>mdi-rhombus-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="trip-item-title">{{trip.title}} <span v-if="is_live(trip)" class="live" ><b>LIVE</b></span> <br/><span class="trip-item-date">{{datetext(trip)}}</span></v-list-item-title>
            </v-list-item-content>
          </v-list-item>


        <v-list-item link
            :to="{ path: '/trips/add'}"
            >
            <v-list-item-icon>
              <v-icon>mdi-rhombus-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title class="trip-item-title">Add trip</v-list-item-title>
            </v-list-item-content>
          </v-list-item>



      </v-navigation-drawer>

    <v-app-bar 
      ref="appBar"
      app
      clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <router-link :to="{ path: '/' }">Home</router-link>
      <v-spacer></v-spacer>
        <div v-if="!status.user">
            <input v-model="email"/>
            <input v-model="password" type="password"/>
            <button @click="login()">Login</button>
        </div>
        <div v-else>
            <v-btn @click="logout()">Logout</v-btn>
      </div>
    </v-app-bar>
    <v-main>
      <router-view :is_logged="is_logged()" ></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    status: {user:undefined},
    email: '',
    password: '',
    drawer:false,
    trips: {}
  }),
      methods: {
    datetext(trip) {
      return trip.datestart.slice(0,7)
    },
    fetch_status() {
       let self = this
       fetch('/api/status').then((response) => {
        return response.json().then((json) => {
            Vue.set(this, 'status', json)
            setTimeout(self.reload, 1000)
        })
        }).catch(function() {
            setTimeout(self.reload, 10000)
        });
    },
    logout() {
       fetch('/api/logout').then((response) => {
        return response.json().then((json) => {
        console.log(json)
          this.fetch_status()
        }).catch(function() {
            
        });
    });
   },
     login() {

        let formData = new FormData();
        formData.append('email', this.email);
        formData.append('password', this.password);


       fetch('/api/login',
         {
           method: 'post',
           body: formData
         }).then((response) => {
        return response.json().then((json) => {
          console.log(json)
          this.fetch_status()
        }).catch(function() {
            
        });
    });
   },

    trips_count() {
        return this.trips.length
    },
    fetch_trips() {
       let self = this
       fetch('/api/trips').then((response) => {
        return response.json().then((json) => {
            Vue.set(this, 'trips', json)

        })
        }).catch(function() {
            setTimeout(self.reload, 10000)
        });
    },
    is_live(trip) {
        return trip.datestop == 'None'
    },

    is_logged() {
        if(this.status.user === null ||  this.status.user === undefined) {
            return false;
        }
        return this.status.user.length > 0
    },

  },
    mounted: function() {
        setTimeout(this.reload, 1000)
        this.fetch_status()
        this.fetch_trips()
  }
};
</script>

<style scoped>
h1 a {
    color:white;
    text-decoration:none;
}

.trip-item-date {
  font-size: 0.5em;
}
</style>
