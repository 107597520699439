import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';

import VueLayers from 'vuelayers'
// import 'vuelayers/lib/style.css' // needs css-loader

Vue.use(VueLayers)

import Home from '@/components/Home'
import TripsView from '@/components/TripsView'
import TripsAdd from '@/components/TripsAdd'
import Router from 'vue-router'

Vue.use(Router)
let router = new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/trips/view/:id',
      name: 'TripsView',
      component: TripsView,
      props: true
    },
    {
      path: '/trips/add',
      name: 'TripsAdd',
      component: TripsAdd,
      props: true
    }
  ]
})


Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
